<template>
    <v-navigation-drawer
            v-model="Sidebar_drawer"
            :dark="SidebarColor !== 'white'"
            :color="SidebarColor"
            mobile-breakpoint="960"
            mini-variant-width="70"
            :expand-on-hover="expandOnHover"
            app
            id="main-sidebar">
        <!---User Area -->
        <v-list-item two-line class="profile-bg">
            <v-img
                src="@/assets/images/logo-private-transparent.png"
                height="65"
                contain/>
        </v-list-item>
        <v-divider></v-divider>
        <v-list dense expand>
            <v-list-group
                no-action
                :value="false"
                prepend-icon="mdi-account-multiple-outline">
                <template v-slot:activator>
                    <v-list-item-title>Administración</v-list-item-title>
                </template>
                <v-list-item
                    v-for="(item, idx) in admins"
                    :key="idx"
                    :to="item.link"
                    link>
                    <v-list-item-icon>
                        <v-icon v-text="item.icon"></v-icon>
                    </v-list-item-icon>
                    <v-list-item-title v-text="item.title"></v-list-item-title>
                </v-list-item>
            </v-list-group>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    import { mapState } from "vuex";
    import VerticalSidebarItems from "./VerticalSidebarItems";

    export default {
        name: "VerticalSidebar",
        props: {
            expandOnHover: {
                type: Boolean,
                default: false,
            },
        },
        data: () => ({
            items: VerticalSidebarItems,
            user: null,
            admins: [
                {title: 'Organización', icon: 'mdi-cog-outline', link: '/admin-company', active: true, grouped: false},
                {title: 'Usuarios', icon: 'mdi-account-circle', link: '/admin-user', active: true, grouped: false},
                {title: 'Contactos', icon: 'mdi-account-multiple', link: '/admin-contacts', active: true, grouped: false},
                {title: 'Buzón', icon: 'mdi-bell-circle', link: '/admin-notifications', active: true, grouped: false},
                {title: 'Preferencias', icon: 'mdi-checkbox-marked-outline', link: '/admin-preferences',
                    active: true, grouped: false},
                {title: "Eventos", icon: 'mdi-view-carousel', link: "/admin-events", active: true, grouped: false},
                {title: 'Proyectos', icon: 'mdi-codepen', link: '/admin-projects', active: true, grouped: false},
                {title: 'Avances', icon: 'mdi-chart-gantt', link: '/admin-advances', active: true, grouped: false},
                {title: 'Categorías', icon: 'mdi-format-list-bulleted', link: '/admin-categories', active: true, grouped: false},
                {title: 'Asignaciones', icon: 'mdi-account', link: '/admin-investors', active: true, grouped: false}
            ]
        }),
        computed: {
            ...mapState(["SidebarColor", "SidebarBg"]),
            Sidebar_drawer: {
                get() {
                    return this.$store.state.Sidebar_drawer;
                },
                set(val) {
                    this.$store.commit("SET_SIDEBAR_DRAWER", val);
                },
            },
            getStatusLogin: function () {
                return this.$store.getters.isLogin;
            }
        },
        watch: {
            /*"$vuetify.breakpoint.smAndDown"(val) {
                this.$emit("update:expandOnHover", !val);
            },*/
        },
        methods: { },
        mounted() {
            this.user = this.$store.getters.getUser;
        }
    };
</script>

7<style lang="scss">
    .v-application--is-ltr .v-list-group--no-action > .v-list-group__items > .v-list-item {
        padding-left: 40px !important;
    }

    .v-application--is-ltr .v-list-item__action:first-child, .v-application--is-ltr .v-list-item__icon:first-child {
        margin-right: 10px !important;
    }

    #main-sidebar {
        box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 1px 0 20px rgba(0, 0, 0, 0.08);
        
        .v-navigation-drawer__border {
            display: none;
        }
        .v-list {
            padding: 2px 2px;
        }
        .v-list-item {
            min-height: 30px;
            &__icon--text,
            &__icon:first-child {
                justify-content: center;
                text-align: center;
                width: 20px;
            }
        }
        .v-list-item__icon i {
            width: 20px;
        }
        .icon-size .v-list-group__items i {
            width: 16px;
            font-size: 16px;
        }
        .profile-bg {
            &.theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
                opacity: 1;
            }
            .v-avatar {
                padding: 15px 0;
            }
        }
        .theme--dark.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
            opacity: 0.5;
            &:hover {
                opacity: 1;
            }
        }
    }
</style>