export default [
    {
        icon: 'mdi-account-multiple-outline',
        title: 'Administración',
        link: null,
        active: true,
        grouped: true,
        children: [
            {title: 'Organización', icon: 'mdi-cog-outline', link: '/admin-company', active: true},
            {title: 'Usuarios', icon: 'mdi-account-circle', link: '/admin-user', active: true},
            {title: 'Contactos', icon: 'mdi-account-multiple', link: '/admin-contacts', active: true},
            {title: 'Preferencias', icon: 'mdi-checkbox-marked-outline', link: '/admin-preferences', active: true},
            {title: "Eventos", icon: 'mdi-view-carousel', link: "/admin-events", active: true},
            {title: 'Proyectos', icon: 'mdi-codepen', link: '/admin-projects', active: true},
            {title: 'Avances', icon: 'mdi-chart-gantt', link: '/admin-advances', active: true},
            {title: 'Categorías', icon: 'mdi-codepen', link: '/admin-categories', active: true},
            {title: 'Asignaciones', icon: 'mdi-account', link: '/admin-investors', active: true}
        ]
    }
    /*{
        icon: 'mdi-animation',  // mdi-apps
        title: 'Secciones',
        link: null,
        active: true,
        grouped: true,
        children: [
            {title: 'Slider del Portal', icon: null, link: '/admin-sliders', active: true},
            {title: 'Quienes Somos', icon: null, link: '/admin-about-us', active: true},
            {
                title: 'Estrategias',
                icon: null,         // link: '/admin-strategy', // Valores, Mision y Vision
                link: null,
                active: true,
                grouped: true,
                children: [
                    {title: "Misión y Visión", icon: null, link: "/admin-vision", active: true},
                    {title: "Valores", icon: null, link: "/admin-values", active: true}
                ]
            },
            {
                title: "Banca Privada",
                icon: null,
                link: null,
                active: true,
                grouped: true,
                children: [
                    {title: "Alianza GBM", icon: null, link: "/admin-alliance", active: true},
                    {title: "Mercado de Capital", icon: null, link: "admin-capital", active: true},
                    {title: "Compra/Venta Divisas", icon: null, link: "admin-sale-purchase", active: true},
                    {title: "Fondos y Ahorros", icon: null, link: "/admin-refund", active: true},
                    {title: "Derivados Financieros", icon: null, link: "/admin-finance", active: true},
                    {title: "Plan de Pesión", icon: null, link: "/admin-pension", active: true}
                ]
            },
            {title: "SOFOM", icon: null, link: "/admin-sofom", active: true},
            {title: "Desarrollo Inmobiliario", icon: null, link: "/admin-develop", active: true},
            {title: "Eventos y Espectáculos", icon: null, link: "/admin-events-other", active: true},
            {title: "Equipo de Trabajo", icon: null, link: "/admin-team", active: true}
        ],
    }*/
];